<template lang='pug'>
  div
    .text-xl.font-bold Список складов
    .text-sm.mb-4 Вы можете просмотреть и настроить склады, в том числе их полки

    template(v-if='warehouses')
      p-table(
        :data='warehouses'
        :search='true'
        :headers='{ "country": "Страна", "addressString": "Адрес", "currency": "Валюта", "comission": "Комиссия", "active": "Активен" }'
        :actions='{ view: showShelves }'
      )
    template(v-else)
      .text-sm.opacity-20.mt-5 Идёт загрузка данных...
</template>

<script>
import { getWarehouses } from '../assets/api.js';

export default {
  mounted() {
    this.loadData();
  },
  data() {
    return {
      warehouses: null
    }
  },
  methods: {
    /**
     * Загрузить данные о складах из БД
     */
    async loadData() {
      const result = await getWarehouses();
      if (!result.success) {
        console.error('Ошибка получения списка стран');
        return;
      }

      result.data.forEach(value => {
        value.addressString = `${value.address.country}, ${value.address.address1}, ${value.address.index}`;
      });

      this.warehouses = result.data;
    },

    /**
     * Показать полки
     */
    showShelves(warehouse) {
      this.$router.push(`/delivery/warehouses/${warehouse._id}`);
    }
  }
}
</script>

<style>

</style>